import React from 'react';
import { useTranslation } from 'react-i18next';
import { AdsSection } from '@/components/AdsSection/AdsSection';
import { ServiceName } from '@/api/types/ads.types';
import { useAdsWithInfiniteScroll } from '@/hooks/useAdsWithInfiniteScroll/useAdsWithInfiniteScroll';
import { MAX_PREMIUM_ADS_COUNT } from '@/components/AdsWithInfiniteScroll/PremiumAdsWithInfiniteScroll.constants';
import { useAdsStore } from '@/store/useAdsStore';
import { useBanner } from '@/hooks/useBanner';
import { useBridgeState } from '@/bridge/hooks';

export const PremiumAdsWithInfiniteScroll = () => {
  const { t } = useTranslation();
  const { isWebView } = useBridgeState();

  const [ads, setAds, nextCursor, setNextCursor] = useAdsStore(state => [
    state.premiumAdsHomePage,
    state.setPremiumAdsHomePage,
    state.premiumAdsNextCursorHomePage,
    state.setPremiumAdsNextCursorHomePage,
  ]);

  const { onLoadMore } = useAdsWithInfiniteScroll({
    adKind: ServiceName.PREMIUM,
    maxCount: MAX_PREMIUM_ADS_COUNT,
    ads,
    setAds,
    nextCursor,
    setNextCursor,
  });

  const { inListBanner: bannerInList } = useBanner();

  return (
    <AdsSection
      className="premiumAds"
      title={t('pages.home.premium_ads')}
      ads={ads}
      onLoadMore={onLoadMore}
      bannerInList={bannerInList}
      {...(!isWebView && { onLoadMore, bannerInList })}
    />
  );
};
